<template>
    <div class="Faculty">
      <navBar></navBar>
      <textEdit title-user="Course Description 1" title-intern-prop="courseDescription1" site-prop="course"></textEdit>
      <textEdit title-user="Course Description 2" title-intern-prop="courseDescription2" site-prop="course"></textEdit>
      <textEdit title-user="Participants" title-intern-prop="participants" site-prop="course" subheader-prop=True></textEdit>
      <listEdit title-user="Financial Terms" title-intern-prop="financialTerms" site-prop="course"></listEdit>
      <textEdit title-user="Location" title-intern-prop="location_course" site-prop="course"></textEdit>
      <listEdit title-user="Repertoire" title-intern-prop="repertoire" site-prop="course"></listEdit>
      <listEdit title-user="Events" title-intern-prop="events_course" site-prop="course"></listEdit>
      <textEdit title-user="Roundtables" title-intern-prop="roundtables" site-prop="course"></textEdit>
      <textEdit title-user="Another Topic" title-intern-prop="another_topic" site-prop="course"></textEdit>
    </div>
</template>

<script>
  import navBar from '@/components/NavBar.vue';
  import textEdit from '@/components/TextEdit.vue';
  import listEdit from '@/components/ListEdit.vue';
  export default {
    data: function () {
      return{

      }
    },
    methods: {

    },
    components: {
      navBar,
      textEdit,
      listEdit
    },
  };
</script>

<style scoped>

</style>
